import { isArray, isDate } from 'lodash';
import 'whatwg-fetch';
import { getHost } from '../helper/getHost';

export const ipServer = `https://${getHost()}`;
/**
 * @namespace ObjectApi
 */
const ObjectApi = {
  /** Ask the backend which user is currently logged into the
   * @public
   * @returns {promise} JSON Object with userId and email property
   */
  getWhosLoggedOnServer() {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=getWhosLoggedOnServer`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },
  /** Ask the backend to verify the token provided on client side by the provider.
   * @param {string} idToken
   * @param {string} provider
   */
  checkToken(idToken, provider) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=checkToken&idToken=${idToken}&idProvider=${provider}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },
  /** Ask the backend to delete the current server session */
  logout() {
    return fetch(`${ipServer}/guerillaPM3/DynAction?requ=logout`, {
      credentials: 'include',
    }).then((response) => response.json());
  },

  checkLogin(login, password) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=checkLogin&login=${login}&password=${password}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  refreshAISynthesis(objId, fieldName, localInstruction) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=refreshAISynthesis&objId=${objId}&fieldName=${fieldName}&localInstruction=${localInstruction}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },
  cleanAISynthesis(objId, fieldName) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=cleanAISynthesis&objId=${objId}&fieldName=${fieldName}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  fetchObjects(objId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=getChildObjectList&parent=${objId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  /** Ask the server to associate the provided file to the node identified by objId
   * @param {string} objId the target node id
   * @param {file} file the uploaded file
   * @param {string} filename the original file name
   * @param {string} attrName the node field name the file will be associated to (ex: contract)
   */
  postFile(objId, file, filename, attrName) {
    try {
      const formData = new FormData();
      if (file) formData.append('file', file, filename);
      return fetch(
        `${ipServer}/guerillaPM3/DynAction?requ=postFile&filename=${encodeURIComponent(
          filename
        )}&objId=${objId}&attrName=${attrName}`,
        {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          body: formData,
        }
      ).then((response) => response.json());
    } catch (error) {}
  },

  getExtractFromExcelFile(file, filename, page) {
    try {
      const formData = new FormData();
      if (file) formData.append('file', file, filename);
      return fetch(
        `${ipServer}/guerillaPM3/DynAction?requ=getExtractFromExcelFile&filename=${encodeURIComponent(
          filename
        )}&pageNumber=${page}`,
        {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          body: formData,
        }
      ).then((response) => response.json());
    } catch (error) {}
  },

  getPreviewFromExcelFile(
    mapping,
    savedFileReference,
    page,
    objectTypeToImport
  ) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=getPreviewFromExcelFile&savedFileReference=${encodeURIComponent(
        savedFileReference
      )}&pageNumber=${page}&objectTypeToImport=${objectTypeToImport}`,
      {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: mapping,
      }
    ).then((response) => response.json());
  },

  postTemplateFile(objId, file, filename, attrName) {
    const formData = new FormData();
    if (file) formData.append('file', file, filename);
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=postTemplateFile&filename=${encodeURIComponent(
        filename
      )}&objId=${objId}&attrName=${attrName}`,
      {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: formData,
      }
    ).then((response) => response.json());
  },

  /** Save JSON icons and images library to the server and retrun filepath of the file */
  saveLibrary(content) {
    return fetch(`${ipServer}/guerillaPM3/DynAction?requ=saveLibrary`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },

      body: content,
    }).then((response) => response.json());
  },

  getIconsLibrary() {
    return fetch(
      `${ipServer}/guerillaPM3/DownloadFile/download/library/library.json`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*',
        },
      }
    ).then((response) => response.json());
  },

  /** Save JSON icon or image for library one the server and retrun filepath of the file */
  saveLibraryAsset(content, filename) {
    const formData = new FormData();
    formData.append('file', content, filename);
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=saveLibraryAsset&filename=${encodeURIComponent(
        filename
      )}`,
      {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: formData,
      }
    ).then((response) => {
      if (!response.ok) {
        throw new Error(`Network response was not ok ${response.statusText}`);
      }
      return response.json();
    });
  },

  addNodeToReadLayer(layerId, nodeId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=addNodeToReadLayer&nodeId=${nodeId}&layerId=${layerId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  addNodeToWriteLayer(layerId, nodeId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=addNodeToWriteLayer&nodeId=${nodeId}&layerId=${layerId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },
  removeNodeFromReadLayer(layerId, nodeId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=removeNodeFromReadLayer&nodeId=${nodeId}&layerId=${layerId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },
  removeNodeFromWriteLayer(layerId, nodeId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=removeNodeFromWriteLayer&nodeId=${nodeId}&layerId=${layerId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },
  applyAudienceRightsToChilds(nodeId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=applyAudienceRightsToChilds&nodeId=${nodeId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },
  saveObject(objId, paramJSON) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=saveObject&objId=${objId}`,
      {
        credentials: 'include',
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paramJSON),
      }
    ).then((response) => response.json());
  },

  socialToggleIcon(objId, iconName) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=socialToggleIcon&objId=${objId}&iconName=${iconName}`,
      {
        credentials: 'include',
        method: 'GET',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
      }
    ).then((response) => response.json());
  },

  getSocialContent(objId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=getSocialContent&objId=${objId}`,
      {
        credentials: 'include',
        method: 'GET',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
      }
    ).then((response) => response.json());
  },

  socialPostComment(objId, comment) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=socialPostComment&objId=${objId}&comment=${comment}`,
      {
        credentials: 'include',
        method: 'GET',
        // mode: 'no-cors',
        // headers: { 'Content-Type': 'application/json' },
        headers: { 'Content-Type': 'text/plain' },
      }
    ).then((response) => response.json());
  },

  sendState(stringState, roomId) {
    return fetch(`${ipServer}/guerillaPM3/DynAction?requ=sendState`, {
      credentials: 'include',
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-Type': 'application/json' },
      body: stringState,
    }).then((response) => response.json());
  },

  pingServer() {
    return fetch(`${ipServer}/guerillaPM3/DynAction?requ=pingServer`, {
      credentials: 'include',
      method: 'GET',
      mode: 'no-cors',
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => response.json());
  },

  getState(stringState, roomId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=getState&roomId=${roomId}`,
      {
        credentials: 'include',
        method: 'GET',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
        body: stringState,
      }
    ).then((response) => response.json());
  },

  voiceToText(objId, attrName, file) {
    const formData = new FormData();
    if (file)
      formData.append('audio', file, `${objId}-${attrName}recording.wav`);
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=executeCommand&command=voicetotext&objId=${objId}&attrName=${attrName}`,
      {
        credentials: 'include',
        method: 'POST',
        mode: 'no-cors',
        body: formData,
      }
    ).then((response) => response.json());
  },

  saveObjectAttribute(
    objId,
    key,
    attrName,
    label,
    defaultValue,
    options,
    displayByDefault,
    allowMultiple,
    required,
    type,
    icon,
    order,
    formulaType,
    formulaObject,
    formulaField,
    formulaCondition,
    formulaConditionJSON,
    formulaCat1,
    formulaCat2,
    tabName,
    refValue,
    maxValue,
    regex,
    instructions,
    readOnly,
    unit,
    canRead,
    canWrite
  ) {
    return fetch(`${ipServer}/guerillaPM3/DynAction?requ=saveObjectAttribute`, {
      credentials: 'include',
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        objId,
        key,
        attrName,
        label,
        defaultValue,
        options,
        displayByDefault,
        allowMultiple,
        required,
        type,
        icon,
        order,
        formulaType,
        formulaObject,
        formulaField,
        formulaCondition,
        formulaConditionJSON,
        formulaCat1,
        formulaCat2,
        tabName,
        refValue,
        maxValue,
        regex,
        instructions,
        readOnly,
        unit,
        canRead,
        canWrite,
      }),
    }).then((response) => response.json());
  },
  exportObject(objId, format = 'xlsx') {
    return fetch(`${ipServer}/guerillaPM3/DynAction?requ=exportObject`, {
      credentials: 'include',
      method: 'POST',
      // disable CORS if you want get data from local
      // mode: 'no-cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        objId: [...(isArray(objId) ? [...objId] : [objId])],
        format,
      }),
    }).then((response) => {
      response.json().then((res) => {
        const link = document.createElement('a');
        link.href = `${ipServer}/guerillaPM3/DownloadFile/download/${res.file}/${res.file}`;
        link.click();
      });
    });
  },

  deleteObject(objId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=deleteObject&objId=${objId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  getUserRole() {
    return fetch(`${ipServer}/guerillaPM3/DynAction?requ=getUserRole`, {
      credentials: 'include',
    }).then((response) => response.json());
  },

  getUserPrivs() {
    return fetch(`${ipServer}/guerillaPM3/DynAction?requ=getUserPrivs`, {
      credentials: 'include',
    }).then((response) => response.json());
  },
  //------------------------------------------------------------------------------------------------
  updateLayoutDocument(objId, fldName) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=updateLayoutDocument&objId=${objId}&fieldName=${fldName}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },
  //------------------------------------------------------------------------------------------------
  selectObject(objId) {
    if (objId == null || objId.length == 0) return;

    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=getObjAttributesList&objId=${objId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },
  //------------------------------------------------------------------------------------------------
  getObjDefList() {
    return fetch(`${ipServer}/guerillaPM3/DynAction?requ=getObjDefList`, {
      credentials: 'include',
    }).then((response) => response.json());
  },
  //------------------------------------------------------------------------------------------------
  getChildsCountByType(arrayParentsId, level) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=getChildsCountByType`,
      {
        credentials: 'include',
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          level,
          arrayParentsId,
        }),
      }
    )
      .then((response) => response.json())
      .catch((err) => {});
  },
  //------------------------------------------------------------------------------------------------
  updateObjectDefinition(objId, label, description, icon, allowUnder) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=updateObjectDefinition`,
      {
        credentials: 'include',
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          objId,
          label,
          description,
          icon,
          allowUnder: JSON.stringify(allowUnder),
        }),
      }
    )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(
          '🚀 ~ file: ObjectApi.js:409 ~ updateObjectDefinition ~ err:',
          err
        );
      });
  },

  //------------------------------------------------------------------------------------------------
  saveObjectDefinition(objId, description, inherit) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=saveObjectDefinition&objId=${objId}&description=${description}&inherit=${inherit}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },
  //------------------------------------------------------------------------------------------------
  deleteObjectDefinition(objId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=deleteObjectDefinition&objId=${objId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  //------------------------------------------------------------------------------------------------
  executeCommand(command, objId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=executeCommand&objId=${objId}&command=${command}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  //------------------------------------------------------------------------------------------------
  suggestWorkflow(objId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=suggestWorkflow&objId=${objId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },
  //------------------------------------------------------------------------------------------------
  suggestModel(objId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=suggestModel&objId=${objId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  //------------------------------------------------------------------------------------------------
  implementModel(objId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=implementModel&objId=${objId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  //------------------------------------------------------------------------------------------------
  deleteObjectAttribute(objAttrId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=deleteObjectAttribute&objAttrId=${objAttrId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },
  //------------------------------------------------------------------------------------------------
  /**
   * Ask backend to create a brother copy of the current node (child ol the same node)
   * @public
   * @param   {string} objId     src node
   * @param   {boolean} [copyChilds=false] copyChilds define if the node childs need to be copied too
   * @returns {promise} the new object Id
   */
  createBrother(objId, copyChilds = false) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=createBrother&objId=${objId}&copyChilds=${copyChilds}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  //------------------------------------------------------------------------------------------------
  getFather(objId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=getFather&objId=${objId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  //------------------------------------------------------------------------------------------------
  getNumberChilds(objId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=getNumberChilds&objId=${objId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  //------------------------------------------------------------------------------------------------

  createFromObjectDefinitionInNode(objId, parentId, perspectiveId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=createFromObjectDefinitionInNode&objId=${objId}&parentId=${parentId}&perspectiveId=${perspectiveId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  createFromObjectDefinitionInNodeOnLayers(
    objId,
    parentId,
    perspectiveId,
    layersRead,
    layersWrite
  ) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=createFromObjectDefinitionInNodeOnLayers`,
      {
        credentials: 'include',
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          objId,
          parentId,
          layersRead,
          layersWrite,
          perspectiveId,
        }),
      }
    ).then((response) => response.json());
  },

  draggedOnNode(objId, parentId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=draggedOnNode&objId=${objId}&parentId=${parentId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  search(term) {
    return fetch(`${ipServer}/guerillaPM3/DynAction?requ=search&term=${term}`, {
      credentials: 'include',
    }).then((response) => response.json());
  },

  getPerspectives() {
    return fetch(`${ipServer}/guerillaPM3/DynAction?requ=getPerspectives`, {
      credentials: 'include',
    }).then((response) => response.json());
  },

  getTreeByPattern(
    pattern,
    qb_restrictions,
    paging_map,
    paging_excluded,
    mandatoryLevel,
    signal
  ) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=getTreeByPattern&pattern=${pattern}`,
      {
        credentials: 'include',
        method: 'POST',
        ...(process.env.NODE_ENV === 'development'
          ? { headers: { 'Content-Type': 'text/plain' } }
          : {
              mode: 'no-cors',
              headers: { 'Content-Type': 'application/json' },
            }),
        body: JSON.stringify({
          qb_restrictions: qb_restrictions,
          paging_map: paging_map ?? {},
          paging_excluded: paging_excluded,
          mandatory_level: mandatoryLevel ?? 0,
        }),
        signal,
      }
    ).then((response) => response.json());
  },

  getCrumbTrail(objId) {
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=getCrumbTrail&objId=${objId}`,
      {
        credentials: 'include',
      }
    ).then((response) => response.json());
  },

  saveObjFormData(objId, objFormData) {
    const paramJSON = {};
    objFormData.map(function (obj, ind) {
      if (obj.type === 'date') {
        paramJSON[obj.attrName] = `${obj.value}`;
      } else if (obj.type !== 'formula') {
        paramJSON[obj.attrName] = obj.value;
      }
      if (obj.multval) {
        paramJSON[`${obj.attrName}_multval`] = obj.multval;
      }
    });

    paramJSON.objId = objId;
    console.log('saveObject_2');

    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=saveObject&objId=${objId}`,
      {
        credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paramJSON),
      }
    ).then((response) => response.json());
  },
  getActivities({
    skip = 0,
    limit,
    actType,
    time,
    includeChilds,
    objId,
    actAuthor,
  }) {
    const queryString = {
      skip,
      limit,
      actType,
      includeChilds,
      objId,
      ...(actAuthor ? { actAuthor } : {}),
    };
    if (isArray(time)) {
      if (isDate(time[0]) && isDate(time[1])) {
        queryString.dateStart = time[0].getTime();
        queryString.dateEnd = time[1].getTime();
      }
    }
    return fetch(
      `${ipServer}/guerillaPM3/DynAction?requ=getActivities&${objToQueryString(
        queryString
      )}`,
      {
        credentials: 'include',
        method: 'GET',
        headers: { 'Content-Type': 'text/plain' },
      }
    ).then((response) => response.json());
  },
};
function objToQueryString(obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    if (encodeURIComponent(obj[key]) !== 'null') {
      keyValuePairs.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      );
    }
  }
  return keyValuePairs.join('&');
}
export default ObjectApi;
